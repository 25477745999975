<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    value?: boolean;
    label?: string;
    id?: string;
  }>(),
  {
    value: true,
    label: "",
    id: "",
  },
);

const value = toRef(props, "value");

const switchedOn = ref(false);

watch(value, () => {
  switchedOn.value = value.value;
}, { immediate: true });

const emit = defineEmits(["select"]);

const onClick = () => {
  switchedOn.value = !switchedOn.value;
  emit("select", switchedOn.value);
};
</script>

<template>
  <div
    :id="id"
    class="relative inline-block cursor-pointer select-none"
    @click="onClick"
  >
    <div
      class="h-full items-center px-2 py-1"
      :class="label ? 'justify-between' : 'flex justify-center'"
    >
      <label
        v-if="label"
        class="inline-block cursor-pointer font-semibold text-ui-grey3"
        :for="label"
      >{{
        label
      }}</label>
      <div
        class="relative top-[2px] ml-3 inline-flex h-4 w-5 items-center rounded-3xl text-center"
        :class="switchedOn ? 'bg-light-royal-blue' : 'bg-[#e5e5e5]'"
      >
        <span
          class="mx-1 inline-block size-3 rounded-full bg-white transition-all duration-300"
          :class="{ 'ml-[calc(100%-20px)] bg-ui-grey': switchedOn }"
        />
      </div>
    </div>
  </div>
</template>
